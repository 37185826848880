<script setup lang="ts">
  import { ref, watch } from 'vue';
  import type { Filter, FilterType, SameValuePair } from '../../page-management-tool/pmt-interfaces.js';
  import DropDown from '../individual-entry-component/drop-down.vue';
  import FilterModal from './filter-modal.vue';
  import activeFilterTable from './active-filter.vue';
  type searchType = "Contains" | "IsEmpty";
  const props = defineProps<{
    customFilterOptions: Map<string, string[]>,
    searchAbleFields: SameValuePair[],
    quickFilterOptions: Filter
  }>();
  const emit = defineEmits<{
    (e: 'customFilter', data: { activeFilters: FilterType[], updateType: "delete" | "add" | "update" }): void
  }>();
  const searchInput = ref<string>("");
  const searchFilter = ref<string>(props.searchAbleFields[0].displayField);
  const showModal = ref<boolean>(false);
  const editFilter = ref<FilterType | null>();
  const activeFilters = ref<FilterType[]>([]);
  const customFilterData = ref<Map<string, string[]>>(props.customFilterOptions);
  const searchType = ref<searchType>("Contains");
  let activeFilterSize = 0;
  const NEVER_CHANGE_DATA = new Map(props.customFilterOptions);

  function HandleCustomFilters(data: FilterType) {
    if (data.index == undefined) {
      data.index = activeFilterSize;
      activeFilters.value.push(data);
    } else {
      activeFilters.value[data.index] = data;
    }
    editFilter.value = null; //for edits make sure to reset value
  }
  function HandleEditFilter(data) {
    editFilter.value = data.filter;
    customFilterData.value = NEVER_CHANGE_DATA;
    showModal.value = true;
  }
  //when text input changes
  watch(searchInput, (newValue) => {
    HandleSearchFilter(searchFilter.value, newValue);
  });
  //when the dropdown changes 
  watch(searchFilter, (newValue) => {
    HandleSearchFilter(newValue, searchInput.value);
  });
  function HandleSearchFilter(fieldToFilter: string, filterValue: string) {
    const searchIndex = activeFilters.value.findIndex(x => x.type === 'search');
    if (searchIndex >= 0) {
      if (filterValue.length === 0 && filterValue.replace(/\s/g, '').length === 0)
        activeFilters.value.splice(searchIndex, 1);
      else {
        activeFilters.value[searchIndex].key = fieldToFilter;
        activeFilters.value[searchIndex].value = filterValue;
      }
    } else {
      if (searchType.value === "IsEmpty" || (filterValue.length !== 0 && filterValue.replace(/\s/g, '').length !== 0)) {
        const newSearchFilter: FilterType = { type: 'search', key: fieldToFilter, value: filterValue, index: activeFilterSize };
        activeFilters.value.push(newSearchFilter);
      }
    }
  }
  watch(activeFilters, (newValue, oldValue) => {
    if (newValue.length < activeFilterSize) {
      emit('customFilter', { activeFilters: newValue, updateType: "delete" });
    }
    else if (newValue.length > activeFilterSize) {
      emit('customFilter', { activeFilters: newValue, updateType: "add" });
    }
    else if (newValue.length == activeFilterSize) {
      emit('customFilter', { activeFilters: newValue, updateType: "update" });
    }
    activeFilterSize = newValue.length;
  }, {
    deep: true,
  });
  watch(searchType, (newValue, oldValue)=>{
    if(newValue === "IsEmpty"){
      searchInput.value = "";
      HandleSearchFilter(searchFilter.value, searchInput.value);
    }
    else if (oldValue === "IsEmpty" && newValue === "Contains"){
      const index = activeFilters.value.findIndex(x => x.type === "search");
      if (index !== -1) {
        activeFilters.value.splice(index, 1);
      }
    }
  });
  function HandleRemoveFilter(index: number) {
    if (activeFilters.value[index].type === "search" && searchType.value === "Contains") {
      searchInput.value = "";
    } else {
      activeFilters.value.splice(index, 1);
      searchType.value = "Contains";
    }
  }
</script>
<template>
  <section class="filters">
    <div class="filter-row flex">
      <div class="search-component flex">
        <div class="search-wrapper flex" :class="searchType == 'IsEmpty' ? 'disabled' :''">
          <DropDown :drop-down-values="searchAbleFields.map(x => x.displayField)"
            :first-value-defualt="true"
            @drop-down-change="(data) => searchFilter = data"
          />
          <input class="search" v-model="searchInput" type="text" placeholder="Search a page...">
        </div>
        <div class="radio-container">
          <input type="radio" name="searchType" value="Contains" v-model="searchType">
          <label for="Contains">Contains</label><br>
          <input type="radio" name="searchType" value="IsEmpty" v-model="searchType">
          <label for="IsEmpty">IsEmpty</label><br>
        </div>
      </div>
      <div class="flex">
        <div class="dropdown">
          <select id="js-quick-filter" name="quick-select">
            <option disabled selected value="none">
              Quick Filter
            </option>
            <option v-for="action in quickFilterOptions?.FilterOptions" :key="action" :value="action">
              {{ action }}
            </option>
          </select>
          <svg class="chevron-down" height="9" viewbox="0 0 14 9" width="14" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L7 7L12 2" stroke="#4E4E50" stroke-linecap="square" stroke-width="2"></path>
          </svg>
        </div>
        <button id="js-add-filter" 
          class="btn btn-teal"
          type="button"
          v-on:click="() => { showModal = true; customFilterData = customFilterOptions }"
        >
          Custom Filters
        </button>
        <FilterModal :filter-data="customFilterData" 
          :editData="editFilter"
          v-if="showModal"
          @close-modal="() => { showModal = !showModal; }"
          @apply-filter="HandleCustomFilters"
        />
      </div>
    </div>
    <div id="js-filter-container"
      class="filter-container"
      :style="[(activeFilters.length === 0) ? 'display: none' : 'block']"
    >
      <activeFilterTable
        v-for="(filter, i) in activeFilters"
        :filter="filter"
        :index="i"
        @edit-filter="HandleEditFilter"
        @remove-filter="HandleRemoveFilter"
      />
    </div>
  </section>
</template>