<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import type { FilterType } from '../../page-management-tool/pmt-interfaces.js';

  const props = defineProps<{
    filterData: Map<string, string[]>,
    editData?: FilterType | null | undefined;
  }>();
  const emit = defineEmits<{
    (e: 'closeModal'): void
    (e: 'applyFilter', data: FilterType): void
  }>();
  const Category = ref<string>("default");
  const Subcategory = ref<string>("");
  onMounted(()=>{
    if(props.editData?.value){
      Category.value = props.editData.key;
    }
  });
  function emitEvents(type: "close" | "filter") {
    if (type === "filter") {
      emit('applyFilter', { type: "custom", key: Category.value, value: Subcategory.value, index: props.editData?.index });
    }
    Category.value = "default";
    emit("closeModal");
  }
  function FilterSelected(event: Event, data: "Category" | "Subcategory") {
    if (data === "Category")
      Category.value = (event.target as HTMLInputElement).value;
    else
      Subcategory.value = (event.target as HTMLInputElement).value;
  }

</script>
<template>
  <div id="js-modal" class="edit-modal">
    <div id="js-modal-content" class="modal-content">
      <!-- modal content goes here -->
      <div id="js-modal-content-filter">
        <p class="step-label">
          <strong>Custom Filter</strong>
        </p>
        <div class="fields flex">
          <select name="js-filter-type-select" id="js-filter-type-select" v-model="Category">
            <option value="default" disabled selected>
              Select a Category
            </option>
            <option v-for="ft in filterData.keys()" :value="ft">
              {{ ft }}
            </option>
          </select>
          <select name="js-filter-action-type" 
            id="js-filter-action-type"
            class="js-filter-value-menu"
            v-if="Category != 'default'"
            @change="FilterSelected($event, 'Subcategory')"
          >
            <option value="none" disabled selected>
              Select a Subcategory
            </option>
            <option v-for="option in filterData.get(Category)" :value="option">
              {{ option }}
            </option>
          </select>
          <button type="button" 
            id="js-filter-submit"
            class="btn btn-purple submit js-filter-submit"
            v-if="Category != 'default' && Subcategory != ''"
            @click="emitEvents('filter')"
          >
            Apply Filter
          </button>
        </div>
      </div>
      <button id="js-modal-close" type="button" class="close-modal-button" @click="emitEvents('close')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0455 8L15.5909 12.5455C15.8636 12.8182 16 13.1818 16 13.5455C16 13.9545 15.8636 14.3182 15.5909 14.5909L14.5909 15.5909C14.2727 15.8636 13.9091 16 13.5455 16C13.1364 16 12.8182 15.8636 12.5455 15.5909L8 11.0455L3.45455 15.5909C3.18182 15.8636 2.81818 16 2.45455 16C2.04545 16 1.68182 15.8636 1.40909 15.5909L0.409091 14.5909C0.136364 14.3182 0 13.9545 0 13.5455C0 13.1818 0.136364 12.8182 0.409091 12.5455L4.95455 8L0.409091 3.45455C0.136364 3.18182 0 2.86364 0 2.45455C0 2.09091 0.136364 1.72727 0.409091 1.40909L1.40909 0.409091C1.68182 0.136364 2.04545 0 2.45455 0C2.81818 0 3.18182 0.136364 3.45455 0.409091L8 4.95455L12.5455 0.409091C12.8182 0.136364 13.1364 0 13.5455 0C13.9091 0 14.2727 0.136364 14.5909 0.409091L15.5909 1.40909C15.8636 1.72727 16 2.09091 16 2.45455C16 2.86364 15.8636 3.18182 15.5909 3.45455L11.0455 8Z"
            fill="#4E4E50"
          />
        </svg>
      </button>
    </div>
  </div>
</template> 
