import { numbersFromZeroToMaxTuple } from "./numbersFromZeroToMax.js";
import { isFunction, isNil, isObject, isPropertyKey, isString } from "./primitive-types.js";
import { convertKebabToCamelCase, isEmptyString } from "./strings.js";


export function fromEntriesTyped(entries) {
  return Object.fromEntries(entries);
}


/**
 * @param {unknown} value 
 * @returns {[...number[]]}
 */
export function getTupleIndicesTuple(value) {
  if (value && typeof value === "object" && "length" in value && typeof value.length === "number") {
    return numbersFromZeroToMaxTuple(value.length);
  }
  return [];
}

/** @type {reverseObjectMapOfArrayIndices} */
export function reverseObjectMapOfArrayIndices(value) {
  const tupleIndices = getTupleIndicesTuple(value);
  const reversedKeyObj = tupleIndices.reduce((result, idx) => {
    const valAtIdx = value[idx];
    if (!isPropertyKey(valAtIdx)) {
      throw new Error(`Value at index is not a valid property key: ${valAtIdx ?? ''}`);
    }
    result[valAtIdx] = idx;
    return result;
  }, {});
  return reversedKeyObj;
}
export function createReverseObjectMap(value) {
  if (isObject(value)) {
    const keys = Object.keys(value);
    return Object.fromEntries(
      keys.map(k => {
        const val = value[k];
        return [val, k];
      })
    );
  }
  throw new Error("Parameter 'value' is not an object");
}

export function exhaustiveGuard(value, message = "ERROR! Reached forbidden guard function with unexpected value") {
  throw new Error(`${message}: ${JSON.stringify(value)}`);
}

export function createEnumFromKeys(keys, camelCaseProps = true) {
  camelCaseProps ??= true;
  return keys.reduce((result, key) => {
    const k = (camelCaseProps ? convertKebabToCamelCase(key) : key);
    result[k] = key;
    return result;
  }, {});
}

export function getTypedObjectKeys(obj) {
  return Object.keys(obj);
}


function checkIsNilOrWhitespaceAfterTrim(value) {
  return (isNil(value) || (isString(value) && isEmptyString(value.trim())));
}
export function defaultIfNilOrWhiteSpace(value, ...options) {
  if (checkIsNilOrWhitespaceAfterTrim(value) === false) {
    return value;
  }
  const numDefaults = options.length;
  // eslint-disable-next-line no-undef-init
  let returnVal = undefined;
  for (let i = 0; i < numDefaults; i++){
    let curOptions = options[i];
    let { getter, defaultValue } = curOptions;
    returnVal = isFunction(getter) ? getter() : defaultValue; 
    if (checkIsNilOrWhitespaceAfterTrim(returnVal) === false) {
      break;
    }
  }
  return returnVal;
}

