<script setup lang="ts">
  import { ref } from "vue";
  import { usePageNodeStore } from "../../page-management-tool/page-node-store.js";
  import type { CustomRequest } from "../../page-management-tool/pmt-interfaces.js";
  import type { PageNodeModel } from "../../page-management-tool/PageNode.js";

  const props = defineProps<{
    dataToEdit: PageNodeModel,
    fieldName: string,
    getter: (obj: any) => any,
  }>();
  const toggleModal = ref<boolean>(false);
  const EditableData = ref<any>("");
  let NoneEditableData = "";
  const saveChangeBtn = ref<boolean>(false);
  const customUrl = ref<boolean>(false);
  //move logic outside component to make it reusable
  const PageNodeStore = usePageNodeStore();
  function handleSave() {
    const customRequest: CustomRequest = {
      TypeOfRequest: "GenericUpdate",
      PageNodeId: props.dataToEdit.getNodeId,
      Field: "UrlName",
      Value: (EditableData.value as string).replace(/\s/g, ""),
      LanguageCode: props.dataToEdit.langSelected
    };
    PageNodeStore.PageUrlUpdate(customRequest).then(()=> 
      ToggleModal()
    );
  }
  function ToggleModal() {
    SetAppropriateDataForEdit();
    if(customUrl.value){
      // eslint-disable-next-line no-alert
      window.alert("This is a custom Url. Please contact a developer.");
      return;
    }
    toggleModal.value = !toggleModal.value;
  }
  function SetAppropriateDataForEdit(){
    const data = props.getter(props.dataToEdit) as string;
    NoneEditableData = props.dataToEdit.LangSpecificPageData.ParentPage;
    // eslint-disable-next-line no-negated-condition
    if (!data.startsWith(NoneEditableData)) {
      customUrl.value = true;
    } else {
      EditableData.value = data.replaceAll(NoneEditableData, "").replaceAll('/', '');
    }
  }
</script>
<template>
  <div class="modal-trigger">
    <svg @click="ToggleModal"
      class="edit-svg"
      :class="{ active: true }"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5625 10.7578C12.5 10.8203 12.5 10.8828 12.5 10.9453V14.4766H1.5V3.47656H9.03125C9.09375 3.47656 9.15625 3.47656 9.21875 3.41406L10.2188 2.41406C10.375 2.25781 10.25 1.97656 10.0312 1.97656H1.5C0.65625 1.97656 0 2.66406 0 3.47656V14.4766C0 15.3203 0.65625 15.9766 1.5 15.9766H12.5C13.3125 15.9766 14 15.3203 14 14.4766V9.94531C14 9.72656 13.7188 9.60156 13.5625 9.75781L12.5625 10.7578ZM17.4375 4.47656C18.1562 3.75781 18.1562 2.60156 17.4375 1.88281L16.0938 0.539062C15.375 -0.179688 14.2188 -0.179688 13.5 0.539062L5.3125 8.72656L5 11.5703C4.90625 12.3828 5.59375 13.0703 6.40625 12.9766L9.25 12.6641L17.4375 4.47656ZM14.375 5.41406L8.5625 11.2266L6.5 11.4766L6.75 9.41406L12.5625 3.60156L14.375 5.41406ZM16.375 2.94531C16.5312 3.07031 16.5312 3.25781 16.4062 3.41406L15.4375 4.38281L13.625 2.53906L14.5625 1.60156C14.6875 1.44531 14.9062 1.44531 15.0312 1.60156L16.375 2.94531Z"
        fill="#C53494"
      />
    </svg>
  </div>
  <div id="js-modal" class="edit-modal" v-if="toggleModal">
    <div id="js-modal-content" class="modal-content">
      <div id="js-modal-title" class="change-content">
        <p class="step-label">
          <strong>Edit {{ fieldName }}</strong>
        </p>
        <div class="flex title-field pop-up-modal">
          <label for="saveChanges">Enter the new {{ fieldName }} for this content and click the "Save
            Changes" button below when you are finished.</label>
          <div class="input-container" style="display: flex;">
            <label class="noneEditable" v-if="NoneEditableData">{{ NoneEditableData }}</label>
            <input style="padding: 5px" type="text" name="saveChanges" v-model="EditableData" @input="()=> saveChangeBtn = true">
          </div>
        </div>
        <div class="submit-field">
          <button type="button" class="save-changes-btn" :class="{ready: saveChangeBtn}" @click="handleSave">
            Save Changes
          </button>
          <p class="small-text">
            Please double check any changes that you make.
          </p>
        </div>
      </div>
      <button id="js-modal-close" type="button" class="close-modal-button" @click="ToggleModal">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0455 8L15.5909 12.5455C15.8636 12.8182 16 13.1818 16 13.5455C16 13.9545 15.8636 14.3182 15.5909 14.5909L14.5909 15.5909C14.2727 15.8636 13.9091 16 13.5455 16C13.1364 16 12.8182 15.8636 12.5455 15.5909L8 11.0455L3.45455 15.5909C3.18182 15.8636 2.81818 16 2.45455 16C2.04545 16 1.68182 15.8636 1.40909 15.5909L0.409091 14.5909C0.136364 14.3182 0 13.9545 0 13.5455C0 13.1818 0.136364 12.8182 0.409091 12.5455L4.95455 8L0.409091 3.45455C0.136364 3.18182 0 2.86364 0 2.45455C0 2.09091 0.136364 1.72727 0.409091 1.40909L1.40909 0.409091C1.68182 0.136364 2.04545 0 2.45455 0C2.81818 0 3.18182 0.136364 3.45455 0.409091L8 4.95455L12.5455 0.409091C12.8182 0.136364 13.1364 0 13.5455 0C13.9091 0 14.2727 0.136364 14.5909 0.409091L15.5909 1.40909C15.8636 1.72727 16 2.09091 16 2.45455C16 2.86364 15.8636 3.18182 15.5909 3.45455L11.0455 8Z"
            fill="#4E4E50"
          />
        </svg>
      </button>
    </div>
  </div>
</template>