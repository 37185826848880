<script setup lang="ts">
  import { inject, ref, onMounted } from 'vue';
  import type { PageNodeModel } from '../../page-management-tool/PageNode.js';
  import type { PropConfig } from '../../page-management-tool/pmt-interfaces.js';
  import rowEntry from './row-entry.vue';
  import csvSelector from './csv-selector.vue';

  const props = defineProps<{
    dataToDisplay: Array<PageNodeModel>,
  }>();

  const currentActiveRow = ref<string>("page-url");
  const sortDirection = ref<string>("desc");
  const stickyClass = ref<string>("");
  const header = ref<HTMLInputElement>();
  let headerOffsetTop = 0;
  const showCSVModal = ref(false);

  const emit = defineEmits<{
    (e: 'sortResults', data: { field: string, dir: string }): void
    (e: 'downloadCsv', data:string[]): void
  }>();

  //inject table configurations
  const tableField = inject("table-config") as PropConfig<object>[];

  function HandleClassAndValuesForSort(headClass) {
    if (currentActiveRow.value === headClass) {
      if (sortDirection.value === "") {
        sortDirection.value = "desc";
      } else {
        sortDirection.value = "";
      }
    }
  }
  function HandleSort(headClass: string, fieldForSort: string) {
    if (fieldForSort) {
      HandleClassAndValuesForSort(headClass);
      currentActiveRow.value = headClass;
      emit('sortResults', { field: fieldForSort, dir: sortDirection.value });
    }
  }
  function downloadCSV(data:string[]) {
    emit('downloadCsv', data);
  }
  function makeHeaderStiky() {
    if (headerOffsetTop === 0) {
      headerOffsetTop = header.value?.offsetTop as number;
    }
    if (window.scrollY >= headerOffsetTop) {
      stickyClass.value = "sticky";
    } else {
      stickyClass.value = "";
    }
  }
  window.onscroll = function () { makeHeaderStiky(); };
  onMounted(() => {
    console.log("On mounted", props.dataToDisplay);
    emit('sortResults', { field: "getUrl", dir: "desc" });
  });
</script>

<template>
  <section class="results">
    <div ref="header" class="head-row sort flex" :class="stickyClass">
      <template v-for="head in tableField" :key="head">
        <div
          :class="[head.class, { 'dropdown-arrow': head.arrow }, (currentActiveRow === head.class) ? 'active' : '', (sortDirection !== 'desc') ? 'descend' : '']"
          @click="HandleSort(head.class, head.fieldForSort)"
        >
          {{ head.title }}
        </div>
      </template>
      <div id="download-csv" class="expand" @click="showCSVModal = !showCSVModal">
        <i class="fas fa-download"></i>
        <span class="tooltiptext">Download CSV</span>
      </div>
    </div>
    <div id="js-result-container" class="result-container">
      <div class="no-results" v-if="dataToDisplay?.length == 0">
        <h4>No results found. Change or remove filters to see more results.</h4>
      </div>
      <rowEntry v-for="(result, index) in dataToDisplay" 
        :data-to-display="result"
        :header-to-field-map="tableField" 
        @new-lang-selected="(data) => { result.langSelected = data }" 
        :display-bucket="false"
        :index="index"
        :key="result.getNodeId"
      />
    </div>
  </section>
  <csvSelector v-if="showCSVModal" @close-modal=" showCSVModal = !showCSVModal" @download-csv="downloadCSV" />
</template>
