<script setup lang="ts">
  import type { FilterType } from '../../page-management-tool/pmt-interfaces.js';

  const props = defineProps<{
    filter: FilterType,
    index: number,
  }>();
  const emits = defineEmits<{
    (e:'removeFilter', index: number): void,
    (e:'editFilter', data:{filter:FilterType, index: number})
  }>();
  function HandleEdit(){
    emits("editFilter", {filter:props.filter, index: props.index});
  }
</script>
<template>
  <div class="filter flex">
    <div class="filter-type">
      {{ filter.key }}
    </div>
    <div class="filter-value">
      {{ filter.value }}
    </div>
    <div class="filter-res">
      Pages Available: {{ filter.resultLength }}
    </div>
    <div class="filter-actions flex">
      <div v-if="filter.type === 'custom'" class="flex">
        <p class="action js-action-edit" @click="HandleEdit">
          Edit
        </p>
        <span class="pipe">|</span>
      </div>
      <p class="action js-remove-filter" @click="()=> emits('removeFilter', index)">
        Remove
        <svg class="remove-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.5 5L9.84375 8.375C9.9375 8.46875 10 8.59375 10 8.71875C10 8.875 9.9375 9 9.84375 9.0625L9.0625 9.84375C8.96875 9.96875 8.84375 10 8.71875 10C8.5625 10 8.4375 9.96875 8.375 9.84375L5 6.5L1.625 9.84375C1.53125 9.96875 1.40625 10 1.28125 10C1.125 10 1 9.96875 0.9375 9.84375L0.15625 9.0625C0.03125 9 0 8.875 0 8.71875C0 8.59375 0.03125 8.46875 0.15625 8.375L3.5 5L0.15625 1.625C0.03125 1.5625 0 1.4375 0 1.28125C0 1.15625 0.03125 1.03125 0.15625 0.9375L0.9375 0.15625C1 0.0625 1.125 0 1.28125 0C1.40625 0 1.53125 0.0625 1.625 0.15625L5 3.5L8.375 0.15625C8.4375 0.0625 8.5625 0 8.71875 0C8.84375 0 8.96875 0.0625 9.0625 0.15625L9.84375 0.9375C9.9375 1.03125 10 1.15625 10 1.28125C10 1.4375 9.9375 1.5625 9.84375 1.625L6.5 5Z"
            fill="#C53494"
          />
        </svg>
      </p>
    </div>
  </div>
</template>