import { createApp } from "vue";
import PageManagementToolApp from "./page-management-tool-App.vue";
import type { Component } from "vue";
import { createPinia } from "pinia";
import type { PageManagementToolProps } from "./PageManagementToolTypes.js";

type AppType = Component<PageManagementToolProps>;
type VueData = Parameters<typeof createApp>[1];

export function initializePageManagementTool(model: PageManagementToolProps) {
    const pinia = createPinia();
    const app = createApp(PageManagementToolApp as AppType, model as unknown as VueData );
    app.use(pinia);
    app.mount("#app");
    app.config.errorHandler = (err) => {
        console.error(err);
    };
}